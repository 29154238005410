@font-face {
    font-family: 'icomoon';
    src: url('../assets/fonts/icomoon.eot?-mts32b');
    src: url('../assets/fonts/icomoon.eot?#iefix-mts32b') format('embedded-opentype'),
    url('../assets/fonts/icomoon.ttf?-mts32b') format('truetype'),
    url('../assets/fonts/icomoon.woff?-mts32b') format('woff'),
    url('../assets/fonts/icomoon.svg?-mts32b#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

i.icon {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Icons colors
.icon-awsec2 {
    color: #f68536;
}

.icon-digitalocean {
    color: #6eb5e6;
}

.icon-ovhcloud {
    color: #264670;
}

.icon-vscale {
    color: #28bbf1;
}

.icon-started {
    color: green;
}

.icon-stopped {
    color: red;
}

.icon-starting,
.icon-stopping {
    color: orange;
}

.icon-alive {
    color: green;
}

.icon-dead {
    color: red;
}

// Icons definition
.icon-vscale:before {
    content: "\e900";
}

.icon-alive:before {
    content: "\e0f8";
}

.icon-dead:before {
    content: "\e0fc";
}

.icon-unknown:before {
    content: "\e29b";
}

.icon-ovhcloud:before {
    content: "\e800";
}

.icon-digitalocean:before {
    content: "\e901";
}

.icon-started:before {
    content: "\f00c";
}

.icon-stopped:before {
    content: "\f00d";
}

.icon-empty:before {
    content: "\f05e";
}

.icon-starting:before {
    content: "\f062";
}

.icon-stopping:before {
    content: "\f063";
}
.icon-sign-out:before {
    content: "\f08b";
}

.icon-awsec2:before {
    content: "\f1b3";
}
