/************************************
 *   IMPORTS
 ************************************/


// Import settings
@import "variables";


// Import base
@import "mixins";
@import "fonts";
@import "icons";
@import "base";
@import "layout";
@import "list";
@import "form";
@import "navbar";


// Import modules


// injector
@import "src/app/home/_home.scss";
@import "src/app/login/_login.scss";
@import "src/app/home/navbar/_navbar.scss";
@import "src/app/home/instances/_instances.scss";
@import "src/app/home/stats/_stats.scss";
@import "src/app/home/instances/instance/_instance.scss";
@import "src/app/home/instances/scaling/_scaling.scss";
// endinjector
