.instances {
    .instances-scaling {
        font-size: 12px;

        button {
            margin-left: 5px;
        }

        .icon-starting,
        .icon-stopping {
            color: black;
        }
    }
}
