.panel-title {
    .form-inline {
        font-size: 12px;

        label {
            font-weight: normal;
        }

        input {
            height: 23px;
            padding: 0 5px;

            font-size: 12px;
        }

        input[type='number'] {
            width: 50px;
        }

        .form-group:not(:first-child) {
            margin-left: 5px;
        }
    }
}
