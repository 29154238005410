.instance {
    position: relative;

    width: 120px;
    height: 110px;

    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;

    text-align: center;

    border: 1px solid $panel-default-border;
    border-radius: 5px;

    .instance-info {
        position: absolute;
        left: 0; right: 0;
        top: 0; bottom: 0;

        margin-top: 10px;
    }

    .instance-button {
        position: absolute;
        left: 0; right: 0;
        top: 0; bottom: 0;

        background: #676767;
        border-radius: 5px;

        cursor: pointer;

        opacity: 0;

        .icon {
            display: block;

            margin-top: 24px;
            font-size: 40px;

            color: #fff;
        }

        &:hover {
            opacity: 0.8;
        }
    }

    .instance-address {
        margin-top: 4px;

        font-size: 11px;
    }

    .instance-region {
        margin-top: 4px;

        font-size: 10px;

        color: $gray-light;
    }

    .instance-icons {
        margin-top: 10px;

        font-size: 18px;
    }

    .instance-type {
        display: inline-block;
    }

    .instance-status,
    .instance-alive {
        display: inline-block;

        margin-left: 5px;
    }
}
