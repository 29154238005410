.panel-title {
    small {
        margin-left: 5px;

        color: $gray-light;
    }

    .form-group,
    input {
        display: inline-block;
    }
}
