body {
    margin-top: $navbar-height + 20px;

    font-family: $text-font;
    font-size: $font-size-base;
    font-weight: 400;
}

a:hover {
    cursor: pointer;
}
