.list-empty {
    width: 100%;

    text-align: center;

    .icon {
        vertical-align: middle;

        font-size: 25px;
    }

    span {
        margin-left: 5px;

        vertical-align: middle;
    }
}
